@import '../../styles/mixins';
.common-report-div {
  background-color: #e0dede;

  /* padding: 5%; */
  width: 80%;
  height: 70%;
  margin-bottom: 10%;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 10px;
  border: none;
}
.common-report-div-2 {
  background-color: #e0dede;

  /* padding: 5%; */
  width: 100%;
  height: 100%;

  border-radius: 10px;
  border: none;
}
.common-report-image {
  /* padding: 5%; */
  background-color: #e0dede;
  width: 100%;
  height: 100%;

  border-radius: 10px;
  border: none;
}
.common-report-image-full {
  /* padding: 5%; */
  background-color: #e0dede;
  width: 100%;
  height: 100%;

  border-radius: 10px;
  border: none;
}

.no-image-div {
  font: 15px Arial, sans-serif;
  text-align: center !important;
  display: inline-block;
  // padding: 30px;
  flex-direction: row;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
}

.modal_content {
  background-color: white;
  position: absolute;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.25);
}

.modalheader {
}
.closebutton {
  position: absolute;
  right: 5px;
  float: right;
  padding: 5px;
}
