@import url('https://fonts.googleapis.com/css?family=Mukta:300,400,700');
.tooltip,
.container__sources:before,
.container__build:before,
.container__db:before,
.container__deploy:before {
  position: absolute;
  right: 0;
  bottom: 100%;
  color: #fff;
  background: #ffb238;
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 0.25rem 0.75rem;
  border-radius: 2.5px;
}
.card,
.container__sources div:not(.icon),
.container__build div {
  line-height: 2;
  background: #fff;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 10px #e6e6e6;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  min-height: 100vh;
  width: 100%;
}
.container {
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
  display: flex;
  // flex-direction: column;
  align-items: center;
  text-align: center;
}
.container svg {
  height: 5rem;
}
.container svg line {
  stroke: #d9d9d9;
  stroke-width: 1px;
}

.clear {
  stroke: #fff;
}

.heading {
  font-size: larger;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  display: block;
}

p {
  font-size: 0.95rem;
  font-weight: 300;
}
.container__sources {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 1.5rem;
  position: relative;
}
.tiles {
  text-align: left;
  margin: 1rem auto;
  width: 200px;
}
.container__build {
  padding: 20px;
  border-radius: 8px;
  background: #f9f9f9;
  position: relative;
  height: 400px;
}
.container__build:before {
  content: 'build';
}
.container__build div {
  margin: 2rem 0;
}
.container__build div svg {
  width: 4rem;
  height: auto;
  fill: #5f39dd;
}
.container__deploy,
.container__db {
  background: #f9f9f9;
  padding: 1.5rem;
  border-radius: 8px;
  position: relative;
}
.container__deploy:before {
  content: 'Mobile';
}
.container__db:before {
  content: 'Database';
}
@media (max-width: 700px) {
  .container__sources {
    flex-direction: column;
  }
  .container__sources div {
    margin: 1rem 0;
  }
}

.blk {
  display: block;
  text-align: center;
  margin-top: 6px;
}

.btn-wf {
  width: 97px;
  border-radius: 4px;
  margin: auto;
  color: white;
}

.retry {
  border: 1px solid red;
  background-color: red;
}

.retry:focus {
  outline-color: brown;
}

.sent {
  // border: 1px solid ÷#00CC00;
  border: 1px solid #9f9f9f;
  // background-color: #9F9F9F
  background-color: #9f9f9f;
  cursor: not-allowed;
}

.send {
  border: 1px solid #00cc00;
  background-color: #00cc00;
}

.send:focus {
  outline-color: green;
}

.center {
  text-align: right;
}

.isMobView {
  display: none;
}

.isWebView {
  display: block;
}

@media (max-width: 700px) {
  .isMobView {
    display: block;
  }

  .isWebView {
    display: none;
  }
}

.infoIcon {
  margin-left: 20px;
  cursor: pointer;
  margin-top: 7px;
  position: absolute;
}

.icon {
  text-align: center;
}

.status {
  position: absolute;
  margin-top: -30px;
  margin-left: 143px;
}

.db-frame {
  width: 340px;
  box-shadow: 0 2px 10px #e6e6e6;
  padding: 15px;
}

.db-details {
  text-align: end;
  margin-top: 5px;
}

.db-title {
  text-align: end;
  line-height: 10px;
}

.db-status {
  font-family: 'ProximaNovaBold';
  font-weight: bold;
  font-size: large;
  margin-right: 18px;
  margin-bottom: 0px;
}

.div-wrapper {
  all: inherit;
  justify-content: center;
  box-shadow: 0 2px 10px #e6e6e6;
}

.main-heading {
  text-align: center;
}

.table-header {
  color: white;
  text-transform: uppercase;
}

.modal-xl {
  width: 900px !important;
}
