@import '../../styles/mixins';

.home-app {
  padding: 0;
}

.dish-header {
  background-color: #bababa;
  padding: 10px;
  margin: 0px !important;
  height: 121px;
}

@media (max-width: 800px) {
  .dish-header {
    height: 92px;
  }
}

.hamburger {
  text-align: center;
  margin-top: 24px;
}

.center {
  text-align: center;
}

.dish-logo {
  width: 350;
  height: 102px;
}

.btn-danger {
  margin-right: 14px;
  margin-top: 13px;
}
.small-screen {
  display: none;
}

.isResponsiveMode {
  display: none;
}

@media (max-width: '990px') {
  .greeting-container {
    display: none;
  }
  .col-logo {
    text-align: end;
  }
}

@media (max-width: '990px') and (min-width: '400px') {
  .small-screen {
    display: block;
  }
}

@media (max-width: '800px') {
  .dish-logo {
    height: 80px;
    width: 250px;
  }
}

@media (max-width: '1024px') {
  .isResponsiveMode {
    display: block;
  }
}

.page-container {
  min-height: 100%;
}
