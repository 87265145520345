@import './mixins';

// Here you put all global css rules.

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
  font-family: 'ProximaNovaMedium';
}

html,
body {
  height: 100%;
}

//Fonts
@font-face {
  font-family: 'ProximaNovaExtraBold';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/proximanova-extrabold.otf'); /* IE9 Compat Modes */
  src: local('ProximaNovaExtraBold'), local('ProximaNovaExtraBold'),
    url('../fonts/proximanova-extrabold.otf') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/proximanova-extrabold.otf') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/proximanova-extrabold.otf') format('woff'),
    /* Modern Browsers */ url('../fonts/proximanova-extrabold.otf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/proximanova-extrabold.otf') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'ProximaNovaBold';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/proximanova-bold.otf'); /* IE9 Compat Modes */
  src: local('ProximaNovaMedium'), local('ProximaNovaMedium'),
    url('../fonts/proximanova-bold.otf') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/proximanova-bold.otf') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/proximanova-bold.otf') format('woff'),
    /* Modern Browsers */ url('../fonts/proximanova-bold.otf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/proximanova-bold.otf') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'ProximaNovaMedium';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/proximanova-medium.otf'); /* IE9 Compat Modes */
  src: local('ProximaNovaMedium'), local('ProximaNovaMedium'),
    url('../fonts/proximanova-medium.otf') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/proximanova-medium.otf') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/proximanova-medium.otf') format('woff'),
    /* Modern Browsers */ url('../fonts/proximanova-medium.otf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/proximanova-medium.otf') format('svg'); /* Legacy iOS */
}

//Font Styles
.proximaNovaExtraBold28 {
  font-family: 'ProximaNovaExtraBold';
  font-size: 28px;
  color: #000;
  display: inline;
}

.proximaNovaExtraBold20 {
  font-family: 'ProximaNovaExtraBold';
  font-size: 20px;
  color: #000;
  display: inline;
}

.proximaNovaMedium20 {
  font-family: 'ProximaNovaMedium';
  font-size: 20px;
  color: #000;
  display: inline;
}

.proximaNovaMedium20 {
  font-family: 'ProximaNovaMedium';
  font-size: 20px;
  color: #000;
  display: inline;
}

.proximaNovaMedium16 {
  font-family: 'ProximaNovaMedium';
  font-size: 16;
  color: #000;
}

.title-text {
  color: #808080;
  text-transform: uppercase;
  text-align: left;
  margin-top: 38px;
  font-weight: bolder;
  margin-bottom: 10px;
}

//Universal Colors
.colorsLightGrey {
  color: #808080;
}

.colorsGrey {
  color: #666666;
}

.colorsBlack {
  color: #1a1a1a;
}

.colorsRed {
  color: #d81f45;
}

a:link {
  color: #808080;
}

/* visited link */
a:visited {
  color: #808080;
}

/* mouse over link */
a:hover {
  color: #808080;
}

/* selected link */
a:active {
  color: #d81f45;
}

@media (max-width: 991px) {
  .title-text {
    color: #808080;
    text-transform: uppercase;
    text-align: left;
    margin-top: 38px;
  }
}

@media (max-width: 600px) and (min-width: 300px) {
  .title-text {
    float: left;
    color: #808080;
    text-transform: uppercase;
    text-align: left;
    margin-top: 38px;
    margin-left: 10px;
    font-size: 20px;
    font-weight: bolder;
  }
}

.display-small-screen {
  display: none;
}

@media (max-width: 990px) {
  .display-small-screen {
    display: block;
  }
}

.responsive-loading {
  font-size: 32px;
}

@media (max-width: 800px) {
  .responsive-loading {
    font-size: 28px;
  }
}
