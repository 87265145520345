@import '../../styles/mixins';

.bg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(3, 16, 29, 0.1); /* Black background with opacity */
  opacity: 2;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  z-index: 100;
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.bg-transparent {
  min-height: 100vh;

  * {
    text-align: center;
  }

  .loader {
    // margin-top: 200px;
  }

  .spinner-border {
    margin-top: 10px;
    margin-left: 10px;
  }
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  // border-left: 6px solid rgba(0, 174, 239, 0.15);
  // border-right: 6px solid rgba(0, 174, 239, 0.15);
  // border-bottom: 6px solid rgba(0, 174, 239, 0.15);
  // border-top: 6px solid rgba(0, 174, 239, 0.8);
  // border-radius: 100%;
}

@media (max-height: 600px) {
  .loader {
    margin-top: 50px !important;
  }
}

@media (max-width: 800px) {
  .responsive {
    font-size: 28px;
  }
}
