.container-class {
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
}

@media (max-width: '700px') {
  .container-class {
    margin: auto;
  }
}

.numeric-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px;
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.report-left-chart {
  // height: 80px;
  // float: left;
}

.report-right-chart {
  height: 80px;
}

@media (max-width: 991px) {
  .report-left-chart {
    float: left;
  }

  .report-right-chart {
    // height: 80px;
  }
}

@media (max-width: 991px) {
  .report-left-chart {
    float: left;
  }

  .report-right-chart {
    // height: 50px;
  }
}
@media (max-width: 600px) and (min-width: 300px) {
  .report-left-chart {
    // height: 80px;
  }

  .report-right-chart {
    // height: 80px;
  }
}

.left-chart {
  height: 350px;
  padding: 20px;
  background-color: lightgray;
}

.right-chart {
  height: 350px;
  padding: 20px;
  background-color: lightskyblue;
}

@media (max-width: 991px) {
  .left-chart {
    height: 275px;
  }

  .right-chart {
    margin-top: 20px;
    height: 275px;
  }
}

@media (max-width: 991px) {
  .left-chart {
    height: 275px;
  }

  .right-chart {
    margin-top: 20px;
    height: 275px;
  }
}
@media (max-width: 600px) and (min-width: 300px) {
  .left-chart {
    height: 250px;
  }

  .right-chart {
    margin-top: 20px;
    height: 250px;
  }
}

@media (max-width: 767px) {
  .small-screen-margin {
    margin: auto;
  }
}

#chart-title {
  background-color: #f5f5f5;
  border-bottom: 1px solid #bbb;
}

#green {
  background-color: #01a362;
  // border: #218b17 5px solid;
}
#red {
  background-color: #ff5151;
  // border:#FF2C2D  5px solid;
}
#blue {
  // border: darkgray 5px solid;
  background-color: #39669a;
}
#yellow {
  // border: darkgray 5px solid;
  background-color: #ffb026;
}
#orange {
  background-color: #fbd69f;
}
#gray {
  background-color: #eee;
}
