@import '../../styles/mixins';

.text-center {
  background: #f2f2f2;
  height: 100vh;
}

.transparent {
  background-color: transparent;
}

.No-margin {
  width: 100%;
  height: 100%;
}

.col {
  paddingleft: 0;
  paddingright: 0;
  padding-left: 0;
  padding-right: 0;
}

body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.filter-div {
  // padding-left: 5%;
  // margin-left: 100%;
  margin-top: 1%;
  height: 0px !important;
  width: 55%;
}

.flex-container {
  flex-direction: column;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  /* NEW, Spec - Firefox, Chrome, Opera */
  height: 100%;
  justify-content: center;
  align-items: center;
}

.table {
  border-collapse: separate;
  border-spacing: 0 20px;
  overflow: auto;
  display: inline;
  table-layout: fixed;
  border-radius: 10px;
  max-width: 800px;
  margin: 0px auto;
  float: none;
  z-index: -1;
}

td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

td:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

table caption {
  caption-side: top;
  color: black;
  font-weight: bold;
}

td {
  font-weight: bold;
  width: 25%;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
  border-radius: 50px;
}

th {
  border: none !important;
  color: #5c5e5c;
  font-weight: normal;
  white-space: nowrap;
  width: 25%;
}

.left-column {
  background-color: gary;
}

.table td.text {
  max-width: 177px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
  }
}

.filter-text {
  /* margin-left: 25%;*/
  font-size: 15px;
}

.filter-container {
  margin: 0 auto;
  padding: 10px;
}

.btn {
  margin: 0 0 30px;
  font-size: 15px !important;
}

.btn-group {
  height: 65px !important;
  border-radius: 0 !important;
}

.calendar-container {
  margin: 0 auto;
  z-index: 1;
  opacity: 0.999;
}

.rdr-DateRange {
  display: inline-flex !important;
  margin: 0 auto;
}

.filter-search {
  top: 1%;
}

.install {
  display: block;
  max-width: 100%;
  max-height: 56%;
}

.fa-eye {
  color: blue;
  margin-left: 50%;
}

.search-bar {
  margin-left: 10%;
  margin-top: 10%;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-top: 5%;
}

.search {
  position: absolute;
  // margin-top: 35px;
  // left: 40%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.search * {
  outline: none;
  // responsive code
  border: 1px solid grey;
  // border-radius: 5px;
}

.search__field {
  outline: red;
  width: 15vw;
  // height:3vw;
  // color: transparent;
  font-family: Lato, sans-serif;
  font-size: 1.35em;
  padding: 0.35em 50px 0.35em 0;
  border: 1px solid red;
  border-radius: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.search__field:focus {
  // border-bottom-color: #ccc;
  width: 15vw;
  height: 3vw;
  color: #2b2b2b;
  cursor: default;
}

.search__field:focus~.search__icon {
  // background-color: transparent;
  cursor: pointer;
  pointer-events: auto;
}

.search__icon {
  position: absolute;
  top: 22%; // default value: 18px
  left: 85%; // default value: 89px
  background-color: transparent; // default value: #e9f1f4
  width: 30px;
  height: 30px;
  font-size: 1.35em;
  text-align: center;
  border-color: transparent;
  border-radius: 50%;
  pointer-events: none;
  display: inline-block;
  transition: background-color 0.2s ease-in-out;
}

.refresh-icon {
  position: absolute;
  top: 20%;
  left: 110%; // default value: 116%
  background-color: #e9f1f4;
  width: 30px;
  height: 30px;
  font-size: 1.35em;
  text-align: center;
  border-color: transparent;
  border-radius: 50%;
  display: inline-block;
}

.search__field::-webkit-input-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition-property: top, color;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}

.search__field:-moz-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition-property: top, color;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}

.search__field::-moz-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition-property: top, color;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}

.search__field:-ms-input-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition-property: top, color;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}

.search__field::-webkit-input-placeholder[style*='hidden'] {
  // color: #83b0c1;
  font-size: 0.65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}

.search__field:-moz-placeholder[style*='hidden'] {
  // color: #83b0c1;
  font-size: 0.65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}

.search__field::-moz-placeholder[style*='hidden'] {
  // color: #83b0c1;
  font-size: 0.65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}

.search__field:-ms-input-placeholder[style*='hidden'] {
  // color: #83b0c1;
  font-size: 0.65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}

.form-control {
  // border: 0px hidden white !important;
  /*color: transparent !important;*/
  width: 192px !important;
  appearance: auto !important;
  border: 0px !important;
}

.form-control:focus {
  // border: 0px hidden white !important;
  color: grey !important;
  height: 30px !important;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.active:hover,
.navbar-light .navbar-nav .nav-link:hover {
  color: #dc3545 !important;
}

.active-class.active {
  color: #dc3545 !important;
}

.a.active {
  color: #dc3545 !important;
}

/*.clear-button{
    color: blue !important;
    width: 10px !important;
    margin-left: 100%;
    margin-top: -20%;
}
.clear-button:focus{
color:red !important;
width: 10px !important;
  margin-left: 50% !important;
    margin-top: -20 !important;
}*/
.table thead th {
  vertical-align: top !important;
}

.fa-refresh {
  margin-left: 200px;
  margin-top: 38px;
}

.menuRootText {
  padding: 10px;
  color: #b0171f !important;
  text-align: left;
}

.col-header {
  font-family: ProximaNovaMedium !important;
}

.submenuBody {
  padding-left: 20px;
  width: 100%;
  display: inline;
  background-color: white;
}

@media (max-width: '500px') {
  .submenuBody {
    padding-left: 2px;
  }
}

// @media(max-width: 991px){
//   #sidebar-wrapper{
//     height: 100vh;
//     z-index: 1;
//     position: relative;
//   }
// }
@media (max-width: 1024px) {
  #sidebar-wrapper {
    height: 100vh;
    z-index: 1;
    position: absolute;
  }
}

// #sidebar-wrapper{
//   height: 100vh;
//   z-index: 1;
//   // position: absolute;
// }
.sidenav {
  transition: 0.5s;
}

.table-container {
  // overflow-x: auto;
  // margin-top: 70px;
}

.react-bootstrap-table {
  overflow: auto;
}

.title-content::before {
  content: 'Collision Reporter Dashboard';
}

@media (max-width: 900px) {
  .title-content::before {
    content: 'COLLISION REPORT';
  }
}

#uploadListTable {
  font-weight: normal;
}

#uploadListTable thead tr th {
  padding-bottom: 10px;
  padding-top: 20px;
}

#uploadListTable tbody tr td {
  padding-bottom: 10px;
  padding-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
}

.search-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid grey;
  padding: 5px;
  margin: 5px;
}

.search-component-input {
  outline: none !important;
  border: none !important;
  border-color: transparent !important;
  font-family: Lato, sans-serif;
  font-size: 16px;
  padding: 3px;
  width: 70%;
}

.search-component-input:focus {
  outline: none !important;
  border: none !important;
  border-color: transparent !important;
}

.search-component-icon {
  background-color: transparent; // default value: #e9f1f4
  width: 30px;
  height: 30px;
  font-size: 1.35em;
  text-align: center;
  border-color: transparent;
  border-radius: 50%;
  pointer-events: none;
  display: inline-block;
  transition: background-color 0.2s ease-in-out;
}

.search-refresh-icon {
  background-color: transparent; // default value: #e9f1f4
  width: 30px;
  height: 30px;
  font-size: 1.35em;
  text-align: center;
  border-color: transparent;
  border-radius: 50%;
  transition: background-color 0.2s ease-in-out;
  margin: 0px 10px !important;
}

.date-range-error {
  border: 1px solid #dc3545;
}