@import '../../../styles/mixins.scss';

.leftmenuwidth {
  width: 250px;
  transition: 0.5s;
}

.menuHeaderTitle {
  font-family: 'ProximaNovaBold';
  background-color: #e6e6e6;
  border-width: 1px;
  border-color: #dfe2e6;
}

.activitylog-tr {
  background-color: #acaba6;
}

.menuRootTitle {
  font-family: 'ProximaNovaBold';
  background-color: #f8f9fa;
  border: 1px solid #dfe2e6;
  vertical-align: middle;
  line-height: 50px;

  height: 50px;
}

.menuSignOutTitle {
  font-family: 'ProximaNovaBold';
  background-color: #f8f9fa;
  border: 1px solid #dfe2e6;
  vertical-align: middle;
  line-height: 50px;

  height: 50px;
}

.menuText {
  padding-left: 20px;
  display: inline;
}

.menuRootText {
  padding: 10px;
  color: black !important;
  /*font: 15px;
  font-weight:bold !important;*/
}

a:hover {
  text-decoration: none !important;
}

.fa-arrow-left {
  margin-left: 2px !important;
  color: black !important;
}

.submenuBody {
  padding-left: 50px;
  padding-right: 10px;
  width: 80%;
  display: inline;
  background-color: white;
  min-height: 100vh;
  // overflow-x: auto;
}

@media (max-width: 1024px) {
  .submenuBody {
    width: 100%;
  }
}

.report_main_section {
  padding: 3px;
  margin: 3px;
  width: 100%;
  color: #333;
  display: inline-block;
  font-family: monospace;
  font-size: 32px;
  text-align: left;
  background-color: white;
  position: relative;
}

@media (max-width: 500px) {
  .small-screen-text-center {
    // text-align: center;
  }
}

.list-group-item.active,
.list-group-item.active:hover {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.p4-black-light {
  font: 16px Arial, sans-serif;
  font-weight: normal;
  color: black;
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
}

.p4-black-light-company-vehicle {
  font: 16px Arial, sans-serif;
  font-weight: normal;
  color: black;
  padding-top: 15px;
  padding-right: 15px;
  // padding-left: 15px;
}

.p4-grey-light {
  padding-left: 3%;
  font: 22px Arial, sans-serif;
  font-weight: normal;
  color: grey;
}

.dander-button {
  margin-left: 3%;
  font: 18px Arial, sans-serif;
  font-weight: normal;
}

.p4-light {
  font: 22px Arial, sans-serif;
  font-weight: bold;
  color: #969799;
}

.h5-heading {
  font: 15px Arial, sans-serif;
  font-weight: normal;
  color: #969799;
  margin-top: 5px;
  margin-left: 10px;
}

.p4-black {
  font: 22px Arial, sans-serif;
  font-weight: bold;
  color: black;
  margin-bottom: 15px;
  margin-left: 10px;
}

.p4-black-fileUpload {
  font: 22px Arial, sans-serif;
  font-weight: bold;
  color: black;
  margin-bottom: 15px;
  margin-left: 10px;
}

.p4-normal {
  font: 22px Arial, sans-serif;
  font-weight: normal;
  color: black;
  margin-left: 10px;
}

.img-display {
  /* padding: 5%; */
  background-color: #e0dede;
  width: 80%;
  height: 70%;
  margin-bottom: 10%;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 10px;
  border: none;
}

.vehicle-image-div {
  transition: transform 0.2s;
  width: 50%;
  height: 70%;
  margin-top: 40px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
  box-shadow: 5px 5px 15px grey;
  border-radius: 10px;
}

.vehicle-center-image {
  height: 100%;
}

@media (max-width: 500px) {
  .vehicle-image-div {
    width: 90%;
    height: 80%;
    margin: 20px auto;
  }
}

.vehicle-image-div:hover {
  transform: scale(1.2);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.wrapper {
  opacity: 0.4;
  filter: alpha(opacity=40);
  /* msie */
  background-color: #000;
}

.font-weight-light {
  font: 14px Arial, sans-serif;
  margin-top: 10px;
  margin-left: 10px;
}

// .raw-img{
// height:300px;
// }
.col-img {
  height: 250px;
}

.col {
  height: 100% !important;
}

.div-right {
  width: 100%;
  height: 725px;
}

.center-img-display {
  /* padding: 5%; */
  background-color: #e0dede;
  width: 80%;
  height: 100%;
  margin-top: 10%;
  margin-right: 10%;
  border-radius: 250px;
  border: none;
}

.p4-black-sub-header {
  font: 16px Arial, sans-serif;
  font-weight: bold;
  color: black;
  padding-top: 15px;
  margin-left: 10px;
}

.table-details {
  border-collapse: separate;
  border-spacing: 0 0px;
  overflow-x: auto;
  /* display: inline; */
  table-layout: fixed;
  border-radius: 8px !important;
  border-width: 2px !important;
  border-style: solid !important;
  max-width: 35%;
  /* margin: 0px auto;*/
  margin-bottom: 20px;
  float: none;
  margin-top: 10px;
  min-width: fit-content;
  margin-left: 12px;
}

@media (max-width: 500px) {
  .center-table {
    margin: auto;
  }
}

.table-details tr:nth-child(even) {
  background-color: white !important;
  border-radius: 20px;
}

.table-details tr,
.table-details tr td {
  border: 1px solid grey;
  padding: 5px;
  /*width:50%;*/
}

.table-details td:first-child {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  width: 15%;
  background: #ebe8e8;
  /*font-weight:bold;*/
}

.table-details td:last-child {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  width: 80%;
}

.table-details td {
  width: 50%;
}

.commentbox {
  width: 500px;
  height: 200px;
  font: 14px Arial, sans-serif;
  margin-left: 10px;
  padding: 10px;
}

@media (max-width: 500px) {
  .commentbox {
    width: 300px;
  }
}

@media (max-width: 1023px) {
  .downloadPdf {
    display: none !important;
  }

  .mobileUpload {
    display: none !important;
  }
}

.downloadJsonbutton {
  //  position: absolute;
  right: 20px;
  // width: 170px;
  // float: right;
  padding: 5px;
}

.defaultDownloadJsonbutton {
  display: block;
  flex-direction: column;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  align-items: flex-end;
}

.responsiveDownloadJsonbutton {
  display: none;
}

.downloadZipbutton {
  display: block;
  flex-direction: column;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  align-items: flex-end;
}

.incidentinfo {
  float: left;
  width: 35%;
  margin: 3px;
  padding: 3px;
}

.incidentmap {
  float: left;
  width: 50%;
  margin-left: 20px;
  margin-right: 20px;
}

@media (max-width: 900px) {
  .incidentmap {
    clear: both;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .incidentinfo {
    width: 100%;
  }
}

.incidenttable-details {
  max-width: 80%;
}

.incidenttable-details-onerow {
  border-collapse: separate;
  border-spacing: 0 0px;
  overflow: auto;
  /* display: inline; */
  table-layout: fixed;
  border-radius: 8px !important;
  border-width: 2px !important;
  border-style: solid !important;
  max-width: 35%;
  /* margin: 0px auto;*/
  margin-bottom: 10px;
  float: none;
  margin-top: 10px;
  background: white;
  max-width: 80%;
  border: 1px solid grey;
  padding: 10px;
}

/* Clear floats after the columns */
.incidentmainview:after {
  width: 100%;
  content: '';
  display: table;
  clear: both;
}

.space {
  height: 50px;
}

@media (max-width: '500px') {
  .submenuBody {
    padding-left: 2px;
    padding-right: 2px;
  }

  // .hide-small-screen{
  //   display: none;
  // }
}

@media (max-width: '991px') {
  .hide-small-screen {
    display: none;
  }
}

@media (max-width: '900px') {
  .defaultDownloadJsonbutton {
    display: none;
  }
}

@media (max-width: '991px') {
  .responsiveDownloadJsonbutton {
    display: none;
  }
}

@media (max-width: '900px') {
  .responsiveDownloadJsonbutton {
    display: block;
    flex-direction: column;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    /* NEW, Spec - Firefox, Chrome, Opera */
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

#default-view {
  display: block;
}

#responsive-view {
  display: none;
}

@media (max-width: 500px) {
  #default-view {
    display: none;
  }

  #responsive-view {
    display: block;
  }
}

.reportDetailsContainer {
  display: block;
  flex-direction: column;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  justify-content: flex-end;
  align-items: flex-end;
}

@media (max-width: 900px) {
  .overflow {
    overflow-x: auto;
  }
}

.reportStatus {
  /* float: left; */
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 10px;
}

.reportStatusDetails {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}

.subReportDetails {
  /* float: left; */
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  justify-content: center;
  display: inline-block;
  align-items: center;
  flex-direction: column;
  border: 1px solid red;
  width: 150px;
  height: 150px;
}

.modalsTable {
  width: 1600px;
}

.emailContent {
  /* float: left; */
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}

.emailSendButton {
  height: 30px;
  width: 66px;
  border-radius: 4px;
  color: white;
  margin-left: 20px;
}

.emailErrorContent {
  /* float: left; */
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  // border-left: 6px solid rgba(0, 174, 239, 0.15);
  // border-right: 6px solid rgba(0, 174, 239, 0.15);
  // border-bottom: 6px solid rgba(0, 174, 239, 0.15);
  // border-top: 6px solid rgba(0, 174, 239, 0.8);
  // border-radius: 100%;
}

.content-loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(3, 16, 29, 0.1); /* Black background with opacity */
  opacity: 2;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  z-index: 100;
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.exportComponent {
  display: block;
}

.exportComponentHide {
  display: none;
}

.hide-unhide-icon {
  width: 24px;
  height: 24px;
  margin-top: -15px;
  margin-left: 25px;
  margin-bottom: 7px;
  padding: 0px !important;
  cursor: pointer;
}